import PageLinkComponent from '@/components/page/linkComponent';
import PageSection from '@/components/page/section';
import TipTapTypography from '@/components/tiptap/readOnlyTipTap';
import type { Policy } from '@/types/schema';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function PolicyLink( { policy }: { policy: Policy } ) {
	const { t } = useTranslation();
	
	if ( !policy ) return null;
	
	return (
		<PageSection primary={t( 'common:policy' )} primaryTypographyProps={{ variant: 'h5' }}>
			<TipTapTypography
				className='ellipsisText5Line'
				sx={{ fontSize: '12px !important', overflow: 'hidden', WebkitLineClamp: 10 }}>
				{policy.policy}
			</TipTapTypography>
			{policy.policy.length > 120 && (
				<Button
					component={PageLinkComponent}
					target='_blank'
					color='primary'
					variant='text'
					sx={{ alignSelf: 'start' }}
					href={`/p/${policy.id}/policy`}>
					{t( 'commerce:read-full-policy' )}
				</Button>
			)}
		</PageSection>
	);
}
