import PageSection from '@/components/page/section';
import currencyFormat from '@/helpers/currencyFormat';
import { calculations } from '@/pages/dashboard/commerce/components/calculations';
import { Order } from '@/types/schema';
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import feeFormat from '../feeFormat';
import type { Pricing } from '../pricing';

export default function Summary( {
	pricing,
	noPrices,
	order,
}: {
	pricing: Pricing,
	noPrices?: boolean,
	order: Order
} ) {
	const { t } = useTranslation();
	const {
		      subTotal,
		      grandTotal,
		      taxTotal,
		      nonTaxPercentLineItemsTaxesData,
		      orderTaxCal,
		      multiTaxesData,
		      orderDiscounts,
	      } = calculations( order, pricing );
	
	return (
		<PageSection primary={!noPrices ? t( 'common:summary' ) : null} primaryTypographyProps={{ variant: 'h5' }}>
			{!noPrices && subTotal > 0 ? (
				<Box sx={{ display: 'flex' }}>
					<Typography className='left' color='text.secondary'>
						{t( 'common:subtotal' )}
					</Typography>
					<Typography className='right'>
						{currencyFormat( subTotal )}
					</Typography>
				</Box>
			) : undefined}
			{subTotal > 0 && !isEmpty( multiTaxesData ) && multiTaxesData.map( ( taxData, index ) => (
				<Box key={index} sx={{ display: 'flex', mt: 1 }}>
					<Typography className='left' color='text.secondary'>
						{taxData[ 0 ]}
						<Typography component='span' color='text.secondary'>
							{taxData[ 3 ] && ` (${taxData[ 1 ]}%)`}
						</Typography>
					</Typography>
					<Typography className='right'>
						{currencyFormat( taxData[ 2 ] )}
					</Typography>
				</Box>
			) ) || []}
			{!isEmpty( nonTaxPercentLineItemsTaxesData ) ? nonTaxPercentLineItemsTaxesData.map( ( taxData, index ) => (
				<Box key={index} sx={{ display: 'flex', mt: 1 }}>
					<Typography className='left' color='text.secondary'>
						{t( 'common:tax' )}
						<Typography component='span' color='text.secondary'>
							{` (${taxData[ 0 ]}%)`}
						</Typography>
					</Typography>
					<Typography className='right'>
						{currencyFormat( taxData[ 1 ] )}
					</Typography>
				</Box>
			) ) : undefined}
			{order.taxPercent <= 0 || orderTaxCal <= 0 || taxTotal <= 0 && isEmpty( orderDiscounts )
				? undefined
				: (
					<Box sx={{ display: 'flex', mt: 1 }}>
						<Typography className='left' color='text.secondary'>
							{t( 'common:tax' )}
							<Typography component='span' color='text.secondary'>
								{` (${order.taxPercent}%)`}
							</Typography>
						</Typography>
						<Typography className='right'>
							{currencyFormat( orderTaxCal )}
						</Typography>
					</Box>
				)}
			{taxTotal ? (
				<Box sx={{ display: 'flex', mt: 1 }}>
					<Typography className='left' color='text.secondary'>
						{t( 'common:taxes' )}
					</Typography>
					<Typography className='right'>
						{currencyFormat( taxTotal )}
					</Typography>
				</Box>
			) : undefined}
			{pricing.prices && [ ...pricing.prices ]?.map( ( fee, index ) => (
				<Box key={index} sx={{ display: 'flex', mt: 1 }}>
					<Typography className='left' color='text.secondary'>
						{feeFormat( fee.name, fee.value, fee.isPercent, null )}
					</Typography>
					<Typography
						className='right'>
						{feeFormat( undefined, fee.value, fee.isPercent, pricing )}
					</Typography>
				</Box>
			) )}
			{!noPrices && (
				<Box sx={{ display: 'flex', my: 1 }}>
					<Typography
						variant='h2'
						className='left'
						color='primary.main'>
						{t( 'common:total' )}
					</Typography>
					<Typography
						variant='h2'
						className='right'
						color='primary.main'>
						{currencyFormat( grandTotal )}
					</Typography>
				</Box>
			)}
		</PageSection>
	);
}
