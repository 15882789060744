import StyledImage from '@/components/styledImage';
import { Box, Typography } from '@mui/material';
import type { AddressBase, Company } from '../../../../types/schema';

export default function Header( { account, address }: { account: Company, address: AddressBase } ) {
	return (
		<Box sx={{ textAlign: 'center', bgcolor: 'divider', p: 2 }}>
			{account?.logo && (
				<StyledImage
					src={account?.logo}
					alt='account-logo'
					sx={{
						width       : 140,
						height      : 'auto',
						maxHeight   : 140,
						borderRadius: 2,
						m           : 'auto',
						mb          : 1,
					}}
				/>
			)}
			<Typography variant='h2' sx={{ mb: 1 }}>
				{account.name}
			</Typography>
			{address && (
				<Box sx={{ textAlign: 'center', mb: .5 }}>
					<Typography>
						{[ address.line1, address.line2 ].filter( Boolean ).join( ', ' )}
					</Typography>
					<Typography>
						{[ address.city, address.state, address.postalCode ].filter( Boolean ).join( ', ' )}
					</Typography>
				</Box>
			)}
		</Box>
	);
}
