import CountryFlag from '@/components/countryFlag';
import Attachment from '@/components/fileUploading/attachment';
import CustomTooltip from '@/components/popups/customTooltip';
import currencyFormat from '@/helpers/currencyFormat';
import safeDateFns, { safeFormatInTimeZone } from '@/helpers/safeFormat';
import { findEarliestDate } from '@/modals/repeatDateModal/utils';
import { theme } from '@/providers/theme';
import type { Order } from '@/types/schema';
import { Avatar, Box, Chip, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { isEmpty, toUpper } from 'lodash-es';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import CommerceStatusChip from '../../../../dashboard/commerce/chips/commerceStatusChip';
import type { Pricing } from '../../pricing';

export default function OrderDetails( {
	order,
	pricing,
	pickup,
	timezone,
}: { order: Order, pricing: Pricing, pickup?: boolean, timezone?: string } ) {
	const { t } = useTranslation();
	
	const clientAddress = order.clientAddress;
	const shippingAddress = order.shippingAddress;
	const isDarkMode = theme.palette.mode === 'dark';
	
	const startingDate: Date = findEarliestDate( order.standingData );
	
	const hideClientInfo = order.metadata?.hideClientInfo;
	return (
		<Fragment>
			<Box sx={{ display: 'flex' }}>
				<Typography className='left' color='text.secondary'>
					{t( 'common:order-number-in-word' )}:
				</Typography>
				<Typography className='right'>
					#{order.metadata?.customNumber || order.number}
				</Typography>
			</Box>
			<Box sx={{ display: 'flex', mt: .5 }}>
				{!order.standing && order.serviceDate && (
					<Typography className='left' color='text.secondary'>
						{t( 'common:service-date' )}:
					</Typography>
				)}
				{!order.standing && order.serviceDate ? (
					<Typography className='right' sx={{ mt: .5 }}>
						{safeFormatInTimeZone( order.serviceDate, 'PPp', timezone || order.companyLocation?.timezone || undefined )}
					</Typography>
				) : !isEmpty( order.standingData ) ? order.standingData?.type !== 'NONE' ? (
					<Typography className='right' sx={{ mt: .5 }}>
						{t( 'common:every' )} {toUpper( order.standingData.type )}
					</Typography>
				) : (
					<CustomTooltip
						toolTipChildren={() => (
							<Box p={1}>
								{order.standingData?.include?.map( ( date, index ) => (
									<Chip
										key={index}
										variant='alpha'
										label={format( date, 'PPP' )}
										sx={{ mr: 1, mt: 1 }}
									/>
								) )}
							</Box>
						)}>
						<Typography className='right'>
							{t( 'common:selected-days' )}
						</Typography>
					</CustomTooltip>
				) : null}
			</Box>
			{order.standingData.type === 'WEEK' ? (
				<Stack
					spacing={1}
					sx={{ '& .MuiAvatar-root': { width: 30, height: 30, fontSize: 12 }, 'justifyContent': 'end', 'mt': 1 }}
					direction='row'>
					{[ 'Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa' ].map( ( day ) => (
						<Avatar
							key={day}
							sx={order.standingData.repeat[ day ] ? { bgcolor: 'primary.main' } : undefined}>
							{day}
						</Avatar>
					) )}
				</Stack>
			) : undefined}
			{order.standing && (
				<Box sx={{ display: 'flex', mt: .5 }}>
					<Typography className='left' color='text.secondary'>{t( 'common:starts' )}:</Typography>
					<Typography className='right' sx={{ mt: .5 }}>
						{safeFormatInTimeZone( order.standingData.type === 'NONE'
							? safeDateFns.min( order?.standingData.include )
							: startingDate, 'PPP' )}
					</Typography>
				</Box>
			)}
			{order.standing && order.standingData?.endDate && order?.standingData?.ends !== 'NEVER'
				&& (
					<Box sx={{ display: 'flex', mt: .5 }}>
						<Typography className='left' color='text.secondary'>{t( 'common:ends' )}</Typography>
						<Typography className='right' sx={{ mt: .5 }}>
							{safeFormatInTimeZone( order.standingData?.endDate, 'PP' )}
						</Typography>
					</Box>
				)}
			{order.standing && order?.standingData.ends === 'OCCURRENCES' && (
				<Box sx={{ display: 'flex', mt: .5 }}>
					<Typography className='left' color='text.secondary'>{t( 'common:occurrences' )}</Typography>
					<Typography className='right' sx={{ mt: .5 }}>
						{order?.standingData.occurrences}
					</Typography>
				</Box>
			)}
			{order.client && !hideClientInfo && (
				<Fragment>
					{order.client.name && (
						<Box sx={{ display: 'flex', mt: .5 }}>
							<Typography className='left' color='text.secondary'>
								{t( 'common:company' )}:
							</Typography>
							<Typography className='right'>
								{order.client.name}
							</Typography>
						</Box>
					)}
					{order.client?.contact && (
						<Box sx={{ display: 'flex', mt: .5 }}>
							<Typography className='left' color='text.secondary'>
								{t( 'common:contact' )}:
							</Typography>
							<Typography className='right'>
								{order.client.contact}
							</Typography>
						</Box>
					)}
					{order.client?.email && (
						<Box sx={{ display: 'flex', mt: .5 }}>
							<Typography className='left' color='text.secondary'>
								{t( 'common:email' )}:
							</Typography>
							<Typography className='right'>
								{order.client.email}
							</Typography>
						</Box>
					)}
					{order.client?.phone && (
						<Box sx={{ display: 'flex', mt: .5 }}>
							<Typography className='left' color='text.secondary'>
								{t( 'common:phone' )}:
							</Typography>
							<Typography className='right'>
								{order.client.phone}
							</Typography>
						</Box>
					)}
					{order.client?.cell && (
						<Box sx={{ display: 'flex', mt: .5 }}>
							<Typography className='left' color='text.secondary'>
								{t( 'common:cell' )}:
							</Typography>
							<Typography className='right'>
								{order.client.cell}
							</Typography>
						</Box>
					)}
				</Fragment>
			)}
			{order.po && (
				<Box sx={{ display: 'flex', mt: .5 }}>
					<Typography className='left' color='text.secondary'>
						{t( 'common:po' )}#:
					</Typography>
					<Typography className='right'>
						{order.po}
					</Typography>
				</Box>
			)}
			{!hideClientInfo && order.clientAddress && (
				<Box sx={{ py: 1 }}>
					<Typography color='text.secondary'>
						{t( 'common:address' )}:
					</Typography>
					<Stack direction='row' sx={{ mt: .5 }}>
						<Typography>
							{[ clientAddress.line1, clientAddress.line2 ].filter( Boolean ).join( ', ' )} {[
							clientAddress.city,
							clientAddress.state,
							clientAddress.postalCode,
						].filter( Boolean ).join( ', ' )}
						</Typography>
					</Stack>
				</Box>
			)}
			{order.shippingAddress && (
				<Box sx={{ py: 1 }}>
					<Fragment>
						{order.shippingAddress?.fullName && (
							<Box sx={{ display: 'flex', mt: .5 }}>
								<Typography className='left' color='text.secondary'>
									Recipient:
								</Typography>
								<Typography className='right'>
									{order.shippingAddress?.fullName}
								</Typography>
							</Box>
						)}
						{order.shippingAddress?.email && (
							<Box sx={{ display: 'flex', mt: .5 }}>
								<Typography className='left' color='text.secondary'>
									{t( 'common:email' )}:
								</Typography>
								<Typography className='right'>
									{order.shippingAddress?.email}
								</Typography>
							</Box>
						)}
						{order.shippingAddress?.phone && (
							<Box sx={{ display: 'flex', mt: .5 }}>
								<Typography className='left' color='text.secondary'>
									{t( 'common:phone' )}:
								</Typography>
								<Typography className='right'>
									{order.shippingAddress?.phone}
								</Typography>
							</Box>
						)}
						{order.client?.cell && (
							<Box sx={{ display: 'flex', mt: .5 }}>
								<Typography className='left' color='text.secondary'>
									{t( 'common:cell' )}:
								</Typography>
								<Typography className='right'>
									{order.client.cell}
								</Typography>
							</Box>
						)}
					</Fragment>
					<Typography color='text.secondary'>
						{t( 'common:shipping-address' )}:
					</Typography>
					<Stack direction='row' sx={{ mt: .5, width: '100%' }}>
						<Typography sx={{ pr: 1 }}>
							{[ shippingAddress.line1, shippingAddress.line2 ].filter( Boolean ).join( ', ' )}
						</Typography>
						<Typography>{[ shippingAddress.city,
						               shippingAddress.state,
						               shippingAddress.postalCode ].filter( Boolean )
							.join( ', ' )}
						</Typography>
						{/*<Box display='inline-flex' alignItems='center'>*/}
						{/*	<Typography sx={{ px: 1 }}>{shippingAddress?.country}</Typography>*/}
						<CountryFlag country={shippingAddress?.country}/>
						{/*</Box>*/}
					</Stack>
				</Box>
			)}
			{order.dueDate && (
				<Box sx={{ display: 'flex', mt: .5 }}>
					<Typography className='left' color='text.secondary'>
						{t( 'common:due-date' )}
					</Typography>
					<Typography className='right'>
						{safeDateFns.isSameDay( order.serviceDate, order.dueDate )
							? t( 'commerce:due-upon-receipt' )
							: safeFormatInTimeZone( order.dueDate, 'PP' )}
					</Typography>
				</Box>
			)}
			{order.deliveryStatus && (
				<Box sx={{ display: 'flex', my: 2 }}>
					<Typography className='left' color='text.secondary'>
						{t( 'common:delivery-status' )}:
					</Typography>
					<Stack className='right' sx={{ justifyContent: 'end', alignItems: 'end' }}>
						<Typography>
							{order.deliveryStatus}
						</Typography>
						<Box
							sx={{
								borderRadius: 2,
								height      : 5,
								width       : '100%',
								bgcolor     : {
									backgroundImage: `linear-gradient(to left, ${{
										'In Process'      : isDarkMode ? '#0f7695' : '#a6e0f1',
										'Out for Delivery': isDarkMode ? '#4c64bb' : '#90a5ef',
										'Delivered'       : isDarkMode ? '#175a29' : '#43a95e',
										'Picked up'       : isDarkMode ? '#1a7834' : '#75c78b',
									}[ order.deliveryStatus ]}, transparent, transparent)`,
								},
							}}
						/>
					</Stack>
				</Box>
			)}
			{order.metadata?.signature && (
				<Box sx={{ display: 'flex', my: 2 }}>
					<Typography className='left' color='text.secondary'>
						{t( 'common:signature' )}:
					</Typography>
					<Box className='right'>
						<Attachment
							removeDownload
							src={order.metadata.signature}
							sx={{ justifySelf: 'end' }}
							imageSX={{ height: 'unset', objectFit: 'cover', border: 'none', background: 'none' }}
						/>
					</Box>
				</Box>
			)}
			{!pickup && pricing.grandTotal > 0 && pricing.remainingAmount > 0 ? (
				<Box
					sx={{
						display   : 'flex',
						mt        : 1,
						whiteSpace: 'nowrap',
					}}>
					<Typography variant='h3' className='left'>
						{t( 'common:amount-due' )}:
					</Typography>
					<Typography variant='h3' className='right'>
						{currencyFormat( Math.max( pricing.remainingAmount, 0 ) )}
					</Typography>
				</Box>
			) : undefined}
			<Box sx={{ textAlign: 'center', my: order.title ? 1 : 2 }}>
				<CommerceStatusChip
					clientView
					cellData={order}
					chipProps={{ sx: { mx: 5, display: 'flex' } }}
				/>
			</Box>
			{order.title && <Typography sx={{ textAlign: 'center', my: 2 }} variant='h5'>{order.title}</Typography>}
		</Fragment>
	);
}
