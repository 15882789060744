import { Box, styled, useTheme } from '@mui/material';
import type { ReactNode } from 'react';

const _SVG = styled( 'svg' )( {} );

export default function ReceiptWrapper( { children }: { children: ReactNode } ) {
	const theme = useTheme();
	const backgroundColor = theme.palette.mode === 'dark'
		? theme.palette.background.paper
		: theme.palette.background.default;
	
	return (
		<Box
			sx={{
				'.left'                : { width: '35%', textAlign: 'left' },
				'.right'               : { width: '65%', textAlign: 'right' },
				'.MuiListItemText-root': { mb: 0 },
			}}>
			<Box
				className='noPrintWrapper'
				sx={{
					overflow    : 'hidden',
					filter      : theme.palette.mode !== 'dark' && 'drop-shadow(0px 10px 15px rgba(75, 75, 75, 0.16))',
					background  : `linear-gradient(${backgroundColor}, ${backgroundColor}) padding-box, linear-gradient(to bottom, ${theme.palette.divider}, transparent) border-box`,
					borderRadius: '15px 15px 0 0',
					border      : '1px solid transparent',
				}}>
				{children}
			</Box>
			<Box sx={{ display: 'flex', mt: -.5, mx: -.05 }}>
				{[ ...Array( 20 ) ].map( ( item, index ) => (
					<_SVG
						key={index}
						viewBox='0 0 100 100'
						sx={{
							transform: 'rotate(180deg)',
							fill     : backgroundColor,
							filter   : ( { palette } ) => theme.palette.mode === 'dark' && `drop-shadow(-.5px -2px 1px ${palette.mode === 'dark'
								? '#504f4f9f'
								: '#504f4f9f'})`,
						}}>
						<polygon points='50 15, 100 100, 0 100'/>
					</_SVG>
				) )}
			</Box>
		</Box>
	);
}
