import ReceiptWrapper from '@/components/receiptWrapper';
import type { Order } from '@/types/schema';
import { Box } from '@mui/material';
import React, { Fragment } from 'react';
import Attachments from '../../attachments';
import CustomFields from '../../customFields';
import SignatureLine from '../../invoiceEstimate/clientView/signatureLine';
import Notes from '../../notes';
import PaymentHistory from '../../payments';
import GetPricing from '../../pricing';
import Header from '../header';
import Summary from '../summary';
import OrderDetails from './details';
import LineItemsOrder from './lineItems';
import MergedSignatures from './mergedSignatures';
import PolicyLink from './policy';

export default function OrderView( {
	order,
	timezone,
	pickup,
}: {
	order: Order,
	timezone?: string,
	pickup?: boolean
} ) {
	const pricing = GetPricing( order );
	
	return (
		<ReceiptWrapper>
			<Header
				account={order.company}
				address={order.companyLocation?.address}
			/>
			<Box sx={{ p: 3 }}>
				<OrderDetails
					pickup={pickup}
					order={order}
					pricing={pricing}
					timezone={timezone}
				/>
				<LineItemsOrder
					pickup={pickup}
					lineItems={order.lineItems}
				/>
				<MergedSignatures order={order}/>
				{!pickup && (
					<Fragment>
						<Summary
							pricing={pricing}
							order={order}
						/>
						<PaymentHistory
							invoice={order}
							timezone={timezone}
							payments={order.payments}
						/>
						<CustomFields customFields={order?.customFields}/>
						<Notes
							notes={order.notes}
							poClient={order?.client?.po}
						/>
						<Attachments
							attachments={order.attachments}
						/>
						{order.metadata?.signatureLine && <SignatureLine/>}
						<PolicyLink
							policy={order.policy}
						/>
					</Fragment>
				)}
			</Box>
		</ReceiptWrapper>
	);
}
