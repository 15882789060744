import currencyFormat from '../../../helpers/currencyFormat';
import type { Pricing } from './pricing';

export default function feeFormat(
	name?: string,
	value?: number,
	isPercent?: boolean,
	pricing?: Pricing,
) {
	if ( name ) return `${name} ${isPercent ? `(${value}%)` : ''}`;
	return currencyFormat( isPercent ? pricing.subTotal * value / 100 : value );
}
